import React, { Component } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header>
      <div className="row">
        <div className="col-lg-2">
          <Link to="/">
            <img
              id="portrait"
              src="/images/photo.jpg"
              height="90"
              width="90"
              alt="Patrick Kortendick"
              title="Patrick Kortendick"
            />
          </Link>
        </div>
        <div className="col-lg-8">
          <h1>
            <strong>Patrick Kortendick</strong>
          </h1>
          <h5>Software consultant. Technology guru, travel enthusiast, sports fan.</h5>
        </div>
        <div className="hidden-xs hidden-sm col-lg-2" id="sports_teams">
          <img
            className="img-fluid"
            src="/images/sports_teams_6.png"
            height="75"
            width="135"
            alt="Sports Teams"
            title="Sports Teams"
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
